var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible:
          _vm.$store.state.multimedia_meetingmode.meetingMembersNameDialog,
        "custom-class": "meetingMembersNameDialog",
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.multimedia_meetingmode,
            "meetingMembersNameDialog",
            $event
          )
        },
        close: _vm.closeDialog
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.limitClick,
              expression: "limitClick"
            }
          ],
          staticClass: "dialogBox",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "#FFFFFF"
          }
        },
        [
          _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("setUsername")))]),
          _c(
            "div",
            { staticClass: "nicknameInput" },
            [
              _c(
                "CommonInput",
                {
                  attrs: { placeholderText: _vm.$store.getters.userInfo.name },
                  on: { inputChange: _vm.getNickname }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-user-fill iconStyle"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "nextStepButton commomButtonHover",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.setNickname.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "iconfont_Me icon-next iconStyle" })]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }