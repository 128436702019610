<template>
  <el-dialog
    :visible.sync="$store.state.multimedia_meetingmode.meetingMembersNameDialog"
    custom-class="meetingMembersNameDialog"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div
      class="dialogBox"
      v-loading="limitClick"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <WindowHeader>{{ $t("setUsername") }}</WindowHeader>
      <div class="nicknameInput">
        <CommonInput
          @inputChange="getNickname"
          :placeholderText="$store.getters.userInfo.name"
        >
          <span class="iconfont_Me icon-user-fill iconStyle"></span>
        </CommonInput>
      </div>
      <div class="nextStepButton commomButtonHover" @click.stop="setNickname">
        <i class="iconfont_Me icon-next iconStyle"></i>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import * as RESTApi from "@/api/rest";
import WindowHeader from "../../../../newVersion/pageViews/loginAndRegister/windowHeader";
import CommonInput from "../../../../newVersion/components/inputComponent/commonInput";
export default {
  components: {
    WindowHeader,
    CommonInput,
  },
  data() {
    return {
      userName: "",
      // 限制点击
      limitClick: false,
    };
  },
  methods: {
    // 获取输入的昵称
    getNickname(value) {
      this.userName = value;
    },
    // 关闭当前窗口
    closeDialog() {
      this.jumpPage({ name: "home" });
    },
    // 设置新的昵称
    async setNickname() {
      if (!this.limitClick) {
        this.limitClick = true;
        await this.handlerInitToursits(true, this.userName);
        if (this.$route.params.data && this.$route.params.data !== "") {
          let params = {
            param: this.$route.params.data,
            deviceType: "web",
          };
          let result = await RESTApi.joinMeetingByAddress(params);
          if (result.code === 200) {
            await this.startQuickJoin_new(result.data.data);
            this.jumpPage({ name: "home" });
          } else {
            this.$message({
              type: "error",
              message: result.message,
              onClose: () => {
                this.jumpPage({ name: "home" });
              },
            });
          }
        }
      }
    },
    // 5.2.0新版本快速加入会议
    startQuickJoin_new(data) {
      // 存储会议房间号
      this.$store.commit("setMeetingChannelName", data.meetingInfo.id);
      this.$store.commit("setMeetingGroupId", data.meetingInfo.id);
      this.$nextTick(() => {
        // 存贮会议发起者的ID
        this.$store.commit("setMeetingCreatorId", data.meetingInfo.creatorId);
        // 设置本次会议开启的时间
        this.$store.commit(
          "setMeetingTimestamp",
          data.sysCurrentTime - data.meetingInfo.meetingTime
        );
        this.$store.commit("setMeetingWindowStatus", true);
        this.$nextTick(() => {
          this.$store.commit("setMeetingMembersNameDialog", false);
        });
      });
    },
  },
};
</script>
<style lang="stylus">
.meetingMembersNameDialog
  width: 450px !important;
  border-radius: 5px !important;
  margin-top 0 !important;
  .el-dialog__body
    padding 30px 40px 50px 40px !important;
    .nicknameInput
      margin-top 40px;
      height 46px;
    .nextStepButton
      height 46px;
      margin-top 30px;
      border-radius 4px;
      background #33CC66;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 24px;
        color #FFFFFF;
</style>
